<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.report">
			<template #title>Self Assessments</template>
			<v-form v-model="valid" ref="form">
				<v-row>
					<v-col cols="12" lg="4">
						<v-select v-model="report_params.corporate_id" :items="corporates" item-text="name" item-value="id" label="Corporate" clearable :rules="requiredRules"></v-select>
					</v-col>
					<v-col cols="12" lg="4"></v-col>
					<v-col cols="12" lg="4" class="d-flex justify-end">
						<v-btn color="success" elevation="2" @click="getData" :loading="loading">
							Get Data
						</v-btn>
					</v-col>
				</v-row>
			</v-form>

			<v-data-table :headers="headers" :items="reportData" item-key="id" :search="search">

				<template v-slot:footer.prepend>
					<v-row>
						<v-col cols="auto">
							<ti-export-to-csv :json-data="reportData" filename="Benchmark Results"/>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>

import {sync} from "vuex-pathify";

export default {
	name: "SelfAssessments",
	computed: {
		corporates: sync('corporates/corporates'),
	},
	mounted() {
		this.init()
	},
	data: () => ({
		valid: false,
		requiredRules: [
			v => !!v || 'Required',
		],
		report_params: {
			corporate_id: null,
		},
		search: '',
		loading: false,
		reportData: [
			{
				user_id: '',
				user_email: '',
				user_first_name: '',
				user_last_name: '',
				topic: '',
				score: 0
			}
		],
		headers: [
			{
				text: 'First name',
				sortable: true,
				value: 'user_first_name',
			},
			{
				text: 'Last name',
				sortable: true,
				value: 'user_last_name',
			},
			{
				text: 'Email',
				sortable: true,
				value: 'user_email',
			},
			{
				text: 'Topic',
				sortable: true,
				value: 'topic',
			},
			{
				text: 'Score',
				align: 'end',
				sortable: true,
				value: 'score',
			},
		],
	}),
	methods: {
		init() {
			this.$store.set('corporates/getCorporates!', null);
		},
		getData() {

			if (this.$refs.form.validate()) {
				this.loading = true;
				this.$api.reports.selfAssessments({report_params: this.report_params})
					.then(response => this.reportData = response.data)
					.catch(error => console.log(error))
					.finally(() => this.loading = false)
			}
		}
	}
}
</script>

<style lang="scss">
table.pvtTable thead tr th, table.pvtTable tbody tr th {
	background: #eee;
}

.pvtUi {
	* {
		font-family: 'roboto', sans-serif;
	}

	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;

	tr td {
		border: 1px solid #333;

		&:first-child {
			width: 300px;
		}
	}
}
</style>